/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import Navbar from "./navbar"
import Footer from "./footer"
import MobileNav from "./mobile-nav"
import NavMobile from "./nav-mobile"
import AnnouncementBanner from "./announcement-banner"
import { Toaster } from "react-hot-toast"

const Layout = ({ children, noNav, noHeader, noBanner, dark }) => {
  return (
    <>
      {/* {!noBanner && <AnnouncementBanner />} */}
      {!noNav && <Navbar dark={dark} noHeader={noHeader} />}
      {!noNav && <NavMobile />}
      <Toaster
        toastOptions={{
          position: "bottom-right",
          style: {
            fontFamily: "Articulat",
            fontSize: 15,
            fontWeight: 500,
          },
        }}
      />
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
