import React from "react"
import styled from "styled-components"
import { IoIosWarning } from "react-icons/io"
import { Colors } from "../colors/Colors"
import { Link } from "gatsby"
// import { colors } from "../../style/colors"

const Container = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.gold};
  background-color: #e0d3b7;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='250' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%e0d3b790' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23bfc4ea' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
`
const Text = styled.p`
  margin: 0;
  color: #220000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 14px;
  }

  a {
    color: #220000;
  }
`
const AnnouncementBanner = () => (
  <Container>
    <Text>
      <Link to="/blog/a-new-era-in-digital-dentistry/">
        Read more about Breaking the Mould! ✨
      </Link>
    </Text>
    {/* <Text>
      <a
        target="_blank"
        href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019"
      >
        Coronavirus disease (COVID-19) updates and tips ✨
      </a>
    </Text> */}
  </Container>
)

export default AnnouncementBanner
