import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import Socials from "./socials"
import { Colors } from "../colors/Colors"
import MainButton from "./main-button"
import {
  CALENDLY_PAGE_SETTINGS,
  CALENDLY_URL,
} from "../../shared/project-constants"
import { openPopupWidget } from "react-calendly"

class Menu extends React.PureComponent {
  state = {
    menuOpen: false,
  }

  closeMenu = event => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.setState({ menuOpen: false })
    }
  }

  openCalendlyWidget = () => {
    openPopupWidget({
      url: CALENDLY_URL,
      prefill: {},
      pageSettings: CALENDLY_PAGE_SETTINGS,
      utm: {},
    })
  }

  componentDidMount() {
    document.addEventListener("keydown", this.closeMenu, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeMenu, false)
  }

  render() {
    return (
      <Wrapper
        onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
        open={this.props.isOpen}
      >
        <MenuList open={this.props.isOpen}>
          <img
            onClick={() => navigate("/")}
            style={{ height: "45px", marginBottom: "40px" }}
            src={require("../images/logo_alt.png")}
          />
          <Item>
            <Link
              to="/"
              activeStyle={{
                color: Colors.gold,
              }}
            >
              Home
            </Link>
          </Item>
          <Item>
            <Link
              to="/about"
              activeStyle={{
                color: Colors.gold,
              }}
              style={{
                "& a": {
                  display: "none",
                },
              }}
            >
              About Us
            </Link>
          </Item>
          <Item>
            <Link
              to="/dentistry"
              // partiallyActive={true}
              activeStyle={{
                color: Colors.gold,
              }}
            >
              Dentistry
            </Link>
          </Item>
          <Item>
            <Link
              to="/dentistry/invisalign"
              partiallyActive={true}
              activeStyle={{
                color: Colors.gold,
              }}
            >
              Invisalign
            </Link>
          </Item>
          <Item>
            <Link
              to="/locations"
              activeStyle={{
                color: Colors.gold,
              }}
            >
              Locations
            </Link>
          </Item>
          <Item space>
            <Link
              to="/blog"
              activeStyle={{
                color: Colors.gold,
              }}
            >
              Blog
            </Link>
          </Item>
          <Item>
            <MainButton onClick={this.openCalendlyWidget}>
              Make an appointment
            </MainButton>
          </Item>
        </MenuList>
        <Socials gold open={this.props.isOpen} />
      </Wrapper>
    )
  }
}
export default Menu

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
    width: ${props => (props.open ? "85vw" : 0)};
    /* border-left: 1px solid ${Colors.gold}; */
    opacity: 0.98;
    box-shadow: 0px 0 5px rgba(0,0,0,0.15);
  transition: width 0.3s;
  z-index: -1;
`
const MenuList = styled.ul`
  margin: 50px 0 0 0;
  list-style: none;
  display: ${props => (props.open ? "block" : "none")};
  transition: opacity 0.1s;
  transition-delay: 0.1s;
  font-family: "Articulat";
  color: black;
  text-align: center;
  opacity: 0.9;
`
const Item = styled.li`
  padding-left: 0;
  margin-bottom: ${props => (props.space ? "2rem" : "0.725rem")};
  color: black;
  opacity: 0.9;

  a {
    font-size: 20px;
    line-height: 1;
    text-decoration: none;
    color: black;
    opacity: 0.9;

    @media only screen and (max-width: 768px) {
      /* font-size: 4rem; */
      /* line-height: 1.5; */
    }
    @media only screen and (max-width: 480px) {
      /* font-size: 3rem; */
      /* line-height: 1.5; */
    }
  }
`
