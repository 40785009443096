import React from "react"
import styled from "styled-components"
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"
import { Colors } from "../colors/Colors"

const SocialsContainer = styled.div`
  display: ${props => (props.open ? "grid" : "none")};
  grid-template-columns: repeat(3, 1fr);
`

const SocialIcon = styled.div`
  font-size: 1.1rem;
  display: inline;
  transition: 0.5s ease;
  margin: 0 7px;
  color: ${props => (props.gold ? Colors.gold : "#FFF0C9")};

  &:hover {
    color: ${Colors.gold};
  }
`

const Socials = props => (
  <SocialsContainer open={props.open}>
    <SocialIcon gold={props.gold}>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/SmileInnTT/"
      >
        <FaFacebookF />
      </a>
    </SocialIcon>
    <SocialIcon gold={props.gold}>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/SmileInnTT/"
      >
        <FaInstagram />
      </a>
    </SocialIcon>
    <SocialIcon gold={props.gold}>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://twitter.com/smileinntt"
      >
        <FaTwitter />
      </a>
    </SocialIcon>
  </SocialsContainer>
)

export default Socials
