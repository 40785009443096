import React from "react"
import styled, { keyframes } from "styled-components"

import logoWhite from "../images/NavLogoWhite.png"
import logoGold from "../images/NavLogoGold.png"
import MainButton from "./main-button"
import { Link, navigate } from "gatsby"
import { Flex, Menu } from "@mantine/core"
import "./navbar.css"
import { Colors } from "../colors/Colors"
import { openPopupWidget } from "react-calendly"
import {
  CALENDLY_PAGE_SETTINGS,
  CALENDLY_URL,
} from "../../shared/project-constants"
import { IoMdArrowRoundForward } from "react-icons/io"

class Navbar extends React.Component {
  state = {
    stuck: false,
    isScrolled: false,
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset
    const { stuck } = this.state
    // console.log(scrollTop)

    if (scrollTop > 65) {
      if (stuck) {
        //Do NOthing, return
        return
      }
      this.setState({ isScrolled: true, stuck: true })
      // this.props.handleSticky(true)
    } else {
      this.setState({ isScrolled: false, stuck: false })
      // this.props.handleSticky(false)
    }
  }

  openCalendlyWidget = () => {
    openPopupWidget({
      url: CALENDLY_URL,
      prefill: {},
      pageSettings: CALENDLY_PAGE_SETTINGS,
      utm: {},
    })
  }

  render() {
    const { isScrolled } = this.state
    return (
      <Container isScrolled={this.props.noHeader || isScrolled}>
        <InnerContainer>
          <LogoContainer onClick={() => navigate("/")}>
            <Logo
              src={isScrolled || this.props.noHeader ? logoGold : logoWhite}
            />
          </LogoContainer>
          <Nav
            dark={this.props.dark}
            isScrolled={this.props.noHeader || isScrolled}
          >
            <NavItem isScrolled={this.props.noHeader || isScrolled}>
              <Link
                activeStyle={{
                  color: isScrolled ? Colors.gold : Colors.cream,
                  fontWeight: 600,
                }}
                style={{ textDecoration: "inherit", color: "inherit" }}
                to="/"
              >
                Home
              </Link>
            </NavItem>
            <NavItem isScrolled={this.props.noHeader || isScrolled}>
              <Link
                activeStyle={{
                  color: isScrolled ? Colors.gold : Colors.cream,
                  fontWeight: 600,
                }}
                style={{ textDecoration: "inherit", color: "inherit" }}
                to="/about"
              >
                About Us
              </Link>
            </NavItem>
            <NavItem isScrolled={this.props.noHeader || isScrolled}>
              <Link
                activeStyle={{
                  color: isScrolled ? Colors.gold : Colors.cream,
                  fontWeight: 600,
                }}
                style={{ textDecoration: "inherit", color: "inherit" }}
                // partiallyActive
                to="/dentistry"
              >
                Dentistry
              </Link>
            </NavItem>
            <Menu trigger="hover" closeDelay={400}>
              <Menu.Target>
                <NavItem isScrolled={this.props.noHeader || isScrolled}>
                  <Link
                    activeStyle={{
                      color: isScrolled ? Colors.gold : Colors.cream,
                      fontWeight: 600,
                    }}
                    style={{ textDecoration: "inherit", color: "inherit" }}
                    // partiallyActive
                    to="/dentistry/invisalign"
                  >
                    Invisalign
                  </Link>
                </NavItem>
              </Menu.Target>
              <Menu.Dropdown style={{ padding: 20 }}>
                <Menu.Item>
                  <Link
                    style={{
                      textDecoration: "none",
                      fontWeight: 500,
                      color: "black",
                      fontSize: 14,
                    }}
                    to="/dentistry/invisalign"
                  >
                    <Flex
                      direction="row"
                      align="center"
                      justify="space-between"
                      style={{ minWidth: 480 }}
                    >
                      <span>Learn more about Invisalign</span>
                      <IoMdArrowRoundForward size={14} />
                    </Flex>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    style={{
                      textDecoration: "none",
                      fontWeight: 500,
                      color: "black",
                      fontSize: 14,
                    }}
                    to="/dentistry/invisalign?title=How much does Invisalign cost?&body=As with other types of orthodontic treatment, the cost of Invisalign is dependent on the complexity of your issue and your course of treatment. As a general rule the cost is comparable to traditional fixed braces."
                  >
                    <Flex
                      direction="row"
                      align="center"
                      justify="space-between"
                      style={{ minWidth: 480 }}
                    >
                      <span>How much does Invisalign cost?</span>
                      <IoMdArrowRoundForward size={14} />
                    </Flex>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    style={{
                      textDecoration: "none",
                      fontWeight: 500,
                      color: "black",
                      fontSize: 14,
                    }}
                    to="/dentistry/invisalign?process=true"
                  >
                    <Flex
                      direction="row"
                      align="center"
                      justify="space-between"
                      style={{ minWidth: 480 }}
                    >
                      <span>Smile Inn's Award Winning Process</span>
                      <IoMdArrowRoundForward size={14} />
                    </Flex>
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    style={{
                      textDecoration: "none",
                      fontWeight: 500,
                      color: "black",
                      fontSize: 14,
                    }}
                    to="/dentistry/invisalign?showComparison=true"
                  >
                    <Flex
                      direction="row"
                      align="center"
                      justify="space-between"
                      style={{ minWidth: 480 }}
                    >
                      <span>Is Invisalign right for you?</span>
                      <IoMdArrowRoundForward size={14} />
                    </Flex>
                  </Link>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>

            <NavItem isScrolled={this.props.noHeader || isScrolled}>
              <Link
                activeStyle={{
                  color: isScrolled ? Colors.gold : Colors.cream,
                  fontWeight: 600,
                }}
                style={{ textDecoration: "inherit", color: "inherit" }}
                to="/locations"
              >
                Locations
              </Link>
            </NavItem>
            <NavItem isScrolled={this.state.isScrolled}>
              <Link
                style={{ textDecoration: "inherit", color: "inherit" }}
                to="/blog"
              >
                Blog
              </Link>
            </NavItem>
          </Nav>
          {/* <Link to="/appointment"> */}
          <MainButton onClick={this.openCalendlyWidget}>
            Book a consultation
          </MainButton>
          {/* </Link> */}
        </InnerContainer>
      </Container>
    )
  }
}
export default Navbar

const Container = styled.div`
  width: 100%;
  padding: 20px 20px;
  background-color: ${props =>
    props.isScrolled ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.15)"};
  box-shadow: ${props => props.isScrolled && "0 2px 4px rgba(0, 0, 0, 0.1)"};
  z-index: 100;
  position: fixed;
  transition: 0.8s ease-out;

  @media (max-width: 800px) {
    display: none;
  }
`
const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`
const LogoContainer = styled.div`
  flex: 1;
  max-width: 200px;
  cursor: pointer;
`
const Logo = styled.img`
  transition: 0.8s ease-out;
  margin: 0;
`
const Nav = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  align-items: center;
  transition: 0.8s ease-out;
  flex: 2;
  color: ${props => (props.isScrolled ? "#434343" : "white")};
  color: ${props => props.dark && "#888"};
`
const Expand = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 60%;
  }
`
const NavItem = styled.li`
  font-family: "Articulat";
  font-size: 0.65rem;
  letter-spacing: 0.5px;
  margin: 0 8px;
  white-space: nowrap;
  text-shadow: ${props => !props.isScrolled && "0 2px 4px rgba(0, 0, 0, 0.65)"};
  text-transform: uppercase;

  :hover {
    ::after {
      content: "";
      background-color: ${Colors.gold};
      width: 0%;
      animation: ${Expand} 0.3s ease-in-out forwards;
      display: block;
      margin: 0 auto;
      height: 3px;
    }
  }
`
const AppointmentButton = styled.div`
  flex: 1;
`
const Button = styled.button``
