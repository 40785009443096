import React, { Component } from "react"
import styled from "styled-components"
import "./mobile-nav.css"
import { Link, navigate } from "gatsby"

const MenuItem = styled.li`
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  display: block;
  color: black;
  transition: 0.3s;
  font-family: "Quicksand", sans-serif;
  position: relative;
  text-decoration: none;
  line-height: 2.5;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  border-bottom: 1px solid rgba(177, 177, 177, 0.3);
  margin-top: 5px;
  transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
    -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
    opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
    opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
    -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  :hover {
    /* background-color: #F8A02F; */
  }
`

class MobileNav extends Component {
  handleHeaderClick() {
    var header = document.querySelector(".header")
    var icon = document.querySelector(".icon-container")
    icon.onclick = function() {
      // header.classList.toggle('menu-open');
    }
    header.classList.toggle("menu-open")
  }
  render() {
    return (
      <div className="container">
        <div className="header" onClick={this.handleHeaderClick}>
          <div className="logo-container">
            <img
              alt="smile-inn-logo"
              onClick={() => navigate("/new-index")}
              src={require("../images/logo.png")}
            />
          </div>
          <div className="icon-container">
            <div id="menuicon">
              <div className="bar bar1"></div>
              <div className="bar bar2"></div>
            </div>
          </div>
          <div className="mobile-menu">
            <Link to="/new-index">
              <img src={require("../images/logo.png")} />
            </Link>
            <ul className="menu">
              {/* <Link style={{color: 'black', textDecoration: 'none'}} href="#home"><MenuItem>Home</MenuItem></Link> */}
              <Link
                style={{ color: "black", textDecoration: "none" }}
                to="/new-index"
              >
                <MenuItem>Home</MenuItem>
              </Link>
              <Link
                style={{ color: "black", textDecoration: "none" }}
                to="/about"
              >
                <MenuItem>About Us</MenuItem>
              </Link>
              <Link
                style={{ color: "black", textDecoration: "none" }}
                to="/dentistry"
              >
                <MenuItem>Dentistry</MenuItem>
              </Link>
              {/* <Link
                style={{ color: "black", textDecoration: "none" }}
                to="/contact-us"
              >
                <MenuItem>Contact Us</MenuItem>
              </Link> */}
              <Link
                style={{ color: "black", textDecoration: "none" }}
                to="/locations"
              >
                <MenuItem>Locations</MenuItem>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileNav
