import React from "react"
import styled from "styled-components"
import { FaPhone, FaBuilding, FaClock } from "react-icons/fa"
import Socials from "./socials"
import { Link } from "gatsby"
import Spacer from "./spacer"

const MainContainer = styled.footer`
  background: black;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px 0;
  color: white;
`
const Logo = styled.img`
  max-width: 300px;
`
const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 80%;
  max-width: 1000px;
  margin: 0 0 30px 0;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
  }
`
const Item = styled.div`
  text-align: center;
  @media (max-width: 750px) {
    margin: 0 0 40px 0;
  }
`
const Title = styled.h3`
  font-family: "Articulat";
  font-size: 15px;
  margin: 0 0 10px 0;
`
const Copy = styled.p`
  font-family: "Articulat";
  font-size: 14px;
  margin: 0;
  color: white;
`
const CopyRight = styled.div`
  background: #0f0f0f;
  padding: 20px 20px;
  margin-top: 20px;
`
const CopyText = styled.p`
  font-family: "Articulat";
  font-size: 14px;
  margin: 0;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
`

const Footer = props => (
  <MainContainer>
    <Container>
      <Link to="/">
        <Logo src={require("../images/logo.png")} />
      </Link>
      <hr />
      <ItemsContainer>
        <Item>
          <Title>
            <FaClock color="#FFF0C9" /> Opening Hours
          </Title>
          <Copy>Mon - Thu 7:00 am - 3:00 pm</Copy>
          <Copy>Sat - 8:00 am - 2:00 pm</Copy>
        </Item>
        <Item>
          <Title>
            <FaBuilding color="#FFF0C9" /> Office Location
          </Title>
          <Copy>#24 Mucurapo Rd, St James</Copy>
        </Item>
        <Item>
          <Title>
            <FaPhone color="#FFF0C9" /> Contact
          </Title>
          <Copy>
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href="tel:868-241-3688"
            >
              868-241-3688
            </a>
          </Copy>
          <Copy>
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href="mailto:smileinntt@gmail.com"
            >
              smileinntt@gmail.com
            </a>
          </Copy>
        </Item>
      </ItemsContainer>
      <Socials open />
      <Spacer size={30} />
      <Logo
        style={{ width: 350, maxWidth: "unset" }}
        src={require("../images/footer-logos.png")}
      />
    </Container>
    <CopyRight>
      <CopyText>© 2023 Smile Inn</CopyText>
    </CopyRight>
  </MainContainer>
)

export default Footer
