import React from "react"
import styled from "styled-components"
import { openPopupWidget } from "react-calendly"
import {
  CALENDLY_PAGE_SETTINGS,
  CALENDLY_URL,
} from "../../../shared/project-constants"
import MainButton from "../main-button"

export const AppointmentButton = ({
  title = "Make an Appointment",
  small = false,
}) => {
  const openCalendlyWidget = () => {
    openPopupWidget({
      url: CALENDLY_URL,
      prefill: {},
      pageSettings: CALENDLY_PAGE_SETTINGS,
      utm: {},
    })
  }

  return small ? (
    <SmallButton onClick={openCalendlyWidget}>{title}</SmallButton>
  ) : (
    <MainButton onClick={openCalendlyWidget}>{title}</MainButton>
  )
}

const SmallButton = styled(MainButton)`
  padding: 2px 15px;
  font-size: 10px;
`
